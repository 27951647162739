import { useOnboardingSteps } from '../features/onboarding/OnboardingPage'
import OnboardingPrompt from '../features/onboarding/OnboardingPrompt'
import { CreateEventButton } from './CreateEventButton/CreateEventButton'
import { profileCanBeEventParticipant } from '../features/profileSelector/profileTypeCanBeParticipant'
import { EventFilterButton } from '../features/eventFeed/EventFilterButton'
import { css } from '@emotion/react'
import { ProfileDetailsFragment } from './ProfileInfo/ProfileInfo'

let container: Style = css({
  display: 'flex',
  flexDirection: 'row',
  a: {
    margin: 0,
  },
  main: {
    flexGrow: 1,
  },
})

let main: Style = css({
  flex: 1,
})

type Props = {
  profile?: ProfileDetailsFragment | null
  hideFilter?: boolean
}

export default function FeedActions({ profile, hideFilter }: Props) {
  const canBeParticipant = profileCanBeEventParticipant(profile)
  const onboardingStepResult = useOnboardingSteps()

  if (!profile) {
    return (
      <div css={container}>
        <div css={main}>
          {onboardingStepResult.allCompleted ? (
            canBeParticipant && <CreateEventButton />
          ) : (
            <OnboardingPrompt />
          )}
        </div>

        {!hideFilter && <EventFilterButton />}
      </div>
    )
  }

  return (
    <div css={container}>
      {
        <div css={main}>
          {onboardingStepResult.allCompleted ? (
            canBeParticipant && <CreateEventButton />
          ) : (
            <OnboardingPrompt />
          )}
        </div>
      }

      {!hideFilter && <EventFilterButton />}
    </div>
  )
}

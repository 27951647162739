import { ButtonAsLink } from '../../components/Button'
import { useCurrentUser } from '../../graphql/hooks'
import { useOnboardingSteps } from './OnboardingPage'

function OnboardingPrompt() {
  let { isAuthenticated } = useCurrentUser()
  const { allCompleted } = useOnboardingSteps()

  if (allCompleted) {
    return null
  }

  return (
    <ButtonAsLink to={isAuthenticated ? '/onboarding' : '/signup'} primary>
      {isAuthenticated ? 'Get started' : 'Sign up'}
    </ButtonAsLink>
  )
}

export default OnboardingPrompt
